@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

/* icon font */
/* @font-face {
  font-family: 'icomoon';
  src: url('../src/assets/font/icomoon.eot?q4ly9y');
  src: url('../src/assets/font/icomoon.eot?q4ly9y#iefix') format('embedded-opentype'),
    url('../src/assets/font/icomoon.ttf?q4ly9y') format('truetype'),
    url('../src/assets/font/icomoon.woff?q4ly9y') format('woff'),
    url('../src/assets/font/icomoon.svg?q4ly9y#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
} */

@font-face {
  font-family: 'salescamp';
  src: url('../src/assets/font/salescamp.eot?3xk7yj');
  src: url('../src/assets/font/salescamp.eot?3xk7yj#iefix') format('embedded-opentype'),
    url('../src/assets/font/salescamp.ttf?3xk7yj') format('truetype'),
    url('../src/assets/font/salescamp.woff?3xk7yj') format('woff'),
    url('../src/assets/font/salescamp.svg?3xk7yj#salescamp') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

*,
::after,
::before {
  box-sizing: border-box;
}
:root {
  --primary-font-stack: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --primary-color: #2e69ce;
  --white-color: #ffffff;
  --gray-color: #868995;
  --light-gray: #bebec1;
  --green-color: #20c997;
  --dark-color: #333333;
  --primary-color-active: #2656a7;
  --primary-color-secondery-active: #cbcbcf;
  --regent-gray-color: #808a9d;
  --main-bg: #f9f9fa;
  --star-bg: #f8d648;
  --yellow-color: #ffc048;
  --deal-column-bg: #f9fafa;
  --danger-Base-bg: #ff322e;
  --danger-Base-active: #d5201c;
  --orange-color: #f08c00;
  --orange-lite-color: #ff6b6b;
  --medium-purple-color: #9775fa;
  --Platinum-color: #e4e6e8;
  --gainsboro-color: #dcdcdc;
  --spanish-gray: #999;
  --before-after-color: rgba(228, 230, 232, 0);
  --mobile-box-shadow: rgba(10, 10, 46, 0.1);
  --pagination-box-shadow: rgba(0, 0, 0, 0.05);
  --pagination-box-shadow-hover: rgba(0, 0, 0, 0.12);
  --maximum-yellow-red: #ffc168;
  --yellow-lite-color: rgba(242, 201, 76, 0.25);
  --independence-color: #4e5971;
  --mango-color: #fbbc04;
  --Cerulean-Crayola: #04a8d5;
  --yellow-green: #92cf48;
  --green-lite: #7eeb6e;
  --red-lite: #dc3732;
  --tomato: #ff6347;
  --Gunmetal: #2c333f;
  --sheen-green: #7ed321;
  --bright-navy-blue: #197dd2;
  --bright-navy-box: rgb(25 125 210 / 50%);
  --roman-silver-color: #81878e;
  --lables-border-color: rgba(189, 189, 189, 0.6);
  --bright-navy-blue: #197dd2;
  --bright-navy-box: rgb(25 125 210 / 50%);
  --cultured-color: #f7f7f7;
  --roman-silver-color: #81878e;
  --lables-border-color: rgba(189, 189, 189, 0.6);
  --Gray-color: #bdbdbd;
  --Red-salsa-color: #eb5757;
  --bright-navy-blue: #197dd2;
  --bright-navy-box: rgb(25 125 210 / 50%);
  --gainsBoro-color: #dbdbdb;
  --roman-silver-color: #81878e;
  --lables-border-color: rgba(189, 189, 189, 0.6);
  --Gray-color: #bdbdbd;
  --Red-salsa-color: #eb5757;
  --disable-color: #b4b5ba;
  --error-bg-color: #feeeee;
  --sonic-silver: #727378;
  --cornsilk-color: #fff6dc;
  --charcoal-color: #585a61;
  --icon-fill: #545f74;
  --Shuttle-Gray: #666a77;
  --invoice-bg: #ff6c5f;
  --calendar-bg: #3b939b;
  --user-bg: #8185e0;
  --email-bg: #1cc7d0;
  --goal-bg: #2dde98;
  --draft-bg: #ff4f81;
  --phone-bg: #f476cb;
  --raven-color: #737987;
  --focus-color: #afcdff;
  --white-smoke: #f8f8f8;

  /* Border Color */
  --gray-border-color: #e4e4e5;
  --danger-Base-border: rgba(0, 0, 0, 0.2);
  --yellow-lite-border: #f2c94c;
  --alice-blue-border: #e3e8ee;
  --charcoal-border: #464950;
  --dark-blue-border: #1e2531;

  /* Icon Color */
  --gray-icon-color: #7c7c7f;
  --icon-blue: #40c4ff;
  --gray-more-icon: #98989c;
  --sea-green-icon: #20c997;
  --mantis-icon: #6fbe6c;
  --callreject-icon: #ff322e;
  --check-icon: #748ffc;
  --message-icon: #ffd600;
  --callminus-icon: rgba(255, 255, 255, 0.7);
  --number-color: rgba(255, 255, 255, 0.6);
  --icon-green: #2dde98;
  --icon-united-nations-blue: #4f96f2;
  --icon-mango: #fbbb00;
  --icon-paradise-pink: #eb466e;
  --kelly-green: #3cbf27;
  --jonquil: #edc626;
  --icon-suggestion: #ed9190;
  --titlebar-bg: #323844;
  --titlebar-tag: #bebec1;
  --unread-color: #fff3e1;
  --organize-navbar-bg: #101318;
  --shade-gray: #e2e2e2;

  /* Button Color */
  --gray-disable-color: #bebec1;

  /* nav color */
  --nav-dark-color: #202632;
  --nav-dark-color-hover: #2e3341;
  --nav-dark-color-focus: #494f5e;

  /*box-shadow*/
  --search-modal-shadow: 0px 5px 25px rgba(0, 0, 0, 0.4), 0px 15px 60px rgba(0, 0, 0, 0.4);
  --modal-box-shadow: rgba(0, 0, 0, 0.15);
  --box-shadow-two: 0px 1px 3px rgba(10, 10, 46, 0.1), 0px 3px 14px rgba(10, 10, 46, 0.04),
    0px 8px 32px rgba(10, 10, 46, 0.08), 0px 30px 84px rgba(10, 10, 46, 0.1);

  /*popup hover*/
  --popup-color-hover: #f2f2f2;
  --text-input-small: rgba(58, 62, 66, 0.15);
  --box-shadow: #f0f0f0;
  --box-shadow-secondary: rgba(37, 37, 38, 0.08);
  --modal-shadow: rgba(0, 0, 0, 0.15);
  --silver-sand: #c5c8cd;
  --menu-modal: rgba(0, 0, 0, 0.1);
  --inbox-hover-color: rgba(0, 0, 0, 0.08);
  --inbox-icon-color: rgba(0, 0, 0, 0.05);
  --Switch-round: rgba(0, 0, 0, 0.12);
  --linear-gradient-white: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 1) 7%,
    #fff 30%,
    #fff 100%
  );
  --collection-linear: linear-gradient(270deg, #202632 20%, #303540 80%);
  --linear-gradient-dark: linear-gradient(180deg, #202632 60%, rgba(32, 38, 50, 0) 100%);
  --linear-gradient-dark-bottom: linear-gradient(360deg, #202632 87%, rgba(32, 38, 50, 0) 100%);
  --linear-gradient-delete: linear-gradient(270deg, #f9fafa -2.3%, #f9fafa 47%, rgba(249, 250, 250, 0) 100%);
  --linear-gradient-more: linear-gradient(270deg, #f9fafa -2.3%, #f9fafa 47%, rgba(249, 250, 250, 0) 100%);
  --linear-gradient-hover: linear-gradient(
    270deg,
    #f2f2f2 0%,
    rgba(242, 242, 242, 0.9) 47.92%,
    rgba(242, 242, 242, 0) 100%
  );
  --black-bg: rgba(0, 0, 0, 0.35);
  --linear-gradient-gray: linear-gradient(0deg, #f7f7f7, #f7f7f7), #fff1e7;
}

html,
body {
  position: fixed;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: var(--primary-font-stack);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

pre {
  white-space: pre-wrap;
}

#root {
  overflow: hidden;
  height: 100%;
}
.boxlist {
  padding: 0px 8px;
}
.sidebarWrapper .arrow-navbar {
  padding: 2px 3px;
}
.sidebarWrapper .sidebarBlock .electron-app {
  margin: 0px 0 10px 0;
}
.sidebarWrapper .sidebarBlock .imgWrapper {
  padding: 0 0px 20px 0px;
}
.sidebarWrapper .arrow-navbar i {
  font-size: 16px;
}
.sidebarWrapper .resize-arrow-navbar {
  max-width: 50px;
  justify-content: center;
}
.sidebar + .resizeWrapper {
  margin-left: -12px;
}
.sidebar + .resizeWrapper .resize {
  left: 106px;
}
blockquote,
pre,
s {
  margin: 0;
  padding: 0;
}
.status-menu .MuiMenu-paper {
  margin-left: 55px;
}

@media only screen and (max-width: 640px) {
  .status-menu .MuiMenu-paper {
    margin-left: 0;
  }
}

.iconsvg {
  height: 20px;
}

.inDevelopment {
  display: none !important;
}

.ql-toolbar.ql-snow .ql-formats button svg {
  display: none;
}
.ql-picker-label svg {
  display: none;
}

.ql-editor.ql-blank::before {
  color: var(--disable-color);
}

.ql-expanded .ql-picker-label::after {
  font-family: 'salescamp';
  content: '\e970';
  font-size: 20px;
  display: inline-block;
  position: absolute;
  top: 1px;
  right: 6px;
}
.ql-picker-label::after {
  font-family: 'salescamp';
  content: '\e925';
  font-size: 20px;
  display: inline-block;
  position: absolute;
  top: 1px;
  right: 6px;
}
.iconsvg {
  height: 20px;
}
.ql-bold::after {
  font-family: 'salescamp';
  content: '\e90d';
  font-size: 16px;
}
.ql-italic::after {
  font-family: 'salescamp';
  content: '\e93d';
  font-size: 16px;
}
.ql-underline::after {
  font-family: 'salescamp';
  content: '\e96f';
  font-size: 16px;
}
.ql-strike::after {
  font-family: 'salescamp';
  content: '\e962';
  font-size: 16px;
}
.ql-blockquote::after {
  font-family: 'salescamp';
  content: '\e955';
  font-size: 16px;
}
.ql-code-block::after {
  font-family: 'salescamp';
  content: '\e918';
  font-size: 16px;
}
.ql-link::after {
  font-family: 'salescamp';
  content: '\e942';
  font-size: 16px;
}
.ql-clean::after {
  font-family: 'salescamp';
  content: '\e92c';
  font-size: 16px;
}
.ql-list[value*='ordered']::after {
  font-family: 'salescamp';
  content: '\e94f';
  font-size: 16px;
}
.ql-list[value*='bullet']::after {
  font-family: 'salescamp';
  content: '\e90f';
  font-size: 16px;
}
.quill_format .ql-size-huge {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: var(--dark-color);
}
@media only screen and (max-width: 640px) {
  .quill_format .ql-size-huge {
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    color: var(--dark-color);
  }
}
.quill_format .ql-size-large {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: var(--dark-color);
}
@media only screen and (max-width: 640px) {
  .quill_format .ql-size-large {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: var(--dark-color);
  }
}
.quill_format .ql-size-small {
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: var(--dark-color);
}
.quill_format .ql-syntax {
  font-weight: normal;
  font-size: 12px;
  color: var(--dark-color);
  padding: 6px 8px;
  background: var(--cultured-color);
  border: 1px solid var(--gray-border-color);
  border-radius: 4px;
  margin: 10px 0;
}
.quill_format p {
  font-size: 14px;
  line-height: 17px;
  font-weight: normal;
  color: var(--dark-color);
}
.quill_format strong {
  color: var(--dark-color);
  font-weight: bold !important;
}
.quill_format em {
  color: var(--dark-color);
}
.quill_format u {
  color: var(--dark-color);
}
.quill_format s {
  color: var(--dark-color);
}
.quill_format ul {
  margin: 10px 0;
}
.quill_format ol {
  margin: 10px 0;
}
.quill_format li {
  font-size: 14px;
  line-height: 17px;
  font-weight: normal;
  color: var(--dark-color);
  margin-bottom: 6px;
}
.quill_format li:last-child {
  margin-bottom: 0;
}
.quill_format a {
  text-decoration: none;
}
.quill_format blockquote {
  font-size: 14px;
  font-weight: 500;
  padding: 4px 0px 4px 10px;
  border-left: 3px solid var(--primary-color);
  margin: 10px 0 10px 12px;
  color: var(--dark-color);
}
